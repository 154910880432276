import { useContext } from 'react'

import { HeaderDispatchContext } from './HeaderContext'

export const useHeaderDispatch = () => {
	const context = useContext(HeaderDispatchContext)

	if (context === undefined) {
		throw new Error('useMenuDispatch must be used within a HeaderProvider')
	}

	return context
}
