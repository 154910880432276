import { useHeaderDispatch } from '../useHeaderContext/useHeaderDispatch'
import { useHeaderState } from '../useHeaderContext/useHeaderState'

export function useHeaderActions() {
	const { isMenuOpen, isSearchOpen, isAccountMenuOpen, isShoppingCartOpen } =
		useHeaderState()
	const dispatch = useHeaderDispatch()

	const openMenu = () => {
		dispatch({
			isMenuOpen: true,
			isSearchOpen: false,
			isAccountMenuOpen: false,
			isShoppingCartOpen: false,
		})
	}

	const closeMenu = () => {
		dispatch({
			isMenuOpen: false,
		})
	}

	const openSearch = () => {
		dispatch({
			isSearchOpen: true,
			isMenuOpen: false,
			isAccountMenuOpen: false,
			isShoppingCartOpen: false,
		})
	}

	const closeSearch = () => {
		dispatch({
			isSearchOpen: false,
		})
	}

	const openAccountMenu = () => {
		dispatch({
			isAccountMenuOpen: true,
			isMenuOpen: false,
			isSearchOpen: false,
			isShoppingCartOpen: false,
		})
	}

	const closeAccountMenu = () => {
		dispatch({
			isAccountMenuOpen: false,
		})
	}

	const openShoppingCart = () => {
		dispatch({
			isShoppingCartOpen: true,
			isMenuOpen: false,
			isSearchOpen: false,
			isAccountMenuOpen: false,
		})
	}

	const closeShoppingCart = () => {
		dispatch({
			isShoppingCartOpen: false,
		})
	}

	return {
		hasMenuToBeOpen: isMenuOpen,
		hasSearchToBeOpen: isSearchOpen,
		hasAccountMenuToBeOpen: isAccountMenuOpen,
		hasShoppingCartToBeOpen: isShoppingCartOpen,
		openMenu,
		closeMenu,
		openSearch,
		closeSearch,
		openAccountMenu,
		closeAccountMenu,
		openShoppingCart,
		closeShoppingCart,
	}
}
