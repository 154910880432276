import { getCookie } from 'utils/cookies/getCookie'

export const auth = (isOldSDK = false) => {
	const userId = getCookie('client_id') || ''

	if (isOldSDK) {
		window.myTSAccountProtection.setAuthenticatedUser(userId)
	} else {
		window.tsPlatform.drs.setAuthenticatedUser(userId)
	}
}
