'use client'

import { useBagTotalItems } from 'cart/hooks/queries/useBagTotalItems'
import { useAnalytics } from 'cart/hooks/useAnalytics'
import { useEffect, useRef } from 'react'

import styles from './TotalItemsLegacy.module.scss'

interface Props {
	withLabel?: boolean
	manageFocus?: (itemRemoved: boolean) => void
}

export const TotalItemsLegacy = ({
	withLabel,
	manageFocus,
}: Props): React.ReactNode => {
	const { totalItems, error } = useBagTotalItems()
	const { sendBagErrorTotalItemsEvent } = useAnalytics()

	const numItems = useRef<number | null>(null)

	useEffect(() => {
		if (totalItems && manageFocus) {
			if (numItems.current) {
				const itemRemoved = numItems.current > totalItems
				manageFocus(itemRemoved)
			}
			numItems.current = totalItems
		}
	}, [totalItems, manageFocus])

	useEffect(() => {
		if (error) {
			sendBagErrorTotalItemsEvent()
		}
	}, [error])

	const buttonClassName = withLabel ? styles.large : styles.small

	return totalItems && totalItems > 0 ? (
		<span data-testid='bag.header.totalItems' className={buttonClassName}>
			{totalItems}
		</span>
	) : null
}
