'use client'

import { usePathname, useSearchParams } from 'next/navigation'
import { useEffect, useRef } from 'react'
import {
	getFromSessionStorage,
	setToSessionStorage,
} from 'utils/webStorage/sessionStorage'

import { PREVIOUS_URL_KEY } from '../constants'

let previousUrl: string | null = null

export const StorePreviousUrl = () => {
	const pathname = usePathname()
	const searchParams = useSearchParams()
	const searchString = searchParams?.toString()

	const previousUrlFromStorage = useRef(
		getFromSessionStorage<string>(PREVIOUS_URL_KEY)
	)

	useEffect(() => {
		previousUrl = previousUrlFromStorage.current
	}, [])

	useEffect(() => {
		if (!pathname?.includes('/login')) {
			if (searchString) {
				setToSessionStorage(PREVIOUS_URL_KEY, `${pathname}?${searchString}`)
				return
			}

			setToSessionStorage(PREVIOUS_URL_KEY, pathname)
		}
	}, [])

	return null
}

export function getStorePreviousUrl() {
	return previousUrl || ''
}
