'use client'

import Script from 'next/script'
import { useSession } from 'session/src/hooks/useSession'

import { OLD_SDK, SDK } from '../../constants/CIAM'
import { useCIAM } from '../../hooks/CIAM/useCIAM/useCIAM'

export function CIAM() {
	const { logged } = useSession()
	const { enabled, isOldSDK, initialize, auth } = useCIAM()
	const url = isOldSDK ? OLD_SDK : SDK

	const init = () => {
		initialize()

		if (logged) {
			auth()
		}
	}

	if (!enabled) {
		return null
	}

	return <Script src={url} strategy='afterInteractive' onLoad={init} />
}
