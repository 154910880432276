export const brandAdapter: { [key: string]: string } = {
	she: 'she',
	he: 'he',
	kids: 'kids',
	nina: 'kids_girl',
	nino: 'kids_boy',
	babyNina: 'baby_girl',
	babyNino: 'baby_boy',
	newBorn: 'baby_newborn',
	teen: 'teen',
	teenA: 'teen_girl',
	teenO: 'teen_boy',
	home: 'home',
	outlet: 'she',
	outletH: 'he',
	outletT: 'teen',
	outletQ: 'teen_girl',
	outletP: 'teen_boy',
	outletK: 'kids',
	outletA: 'kids_girl',
	outletO: 'kids_boy',
	outletBA: 'baby_girl',
	outletBO: 'baby_boy',
	outletNB: 'baby_newborn',
	outletC: 'home',
}
