import { getBrandParent } from 'country-configuration/utils/brands/getBrandParent'
import { matchBrandFromUrl } from 'country-configuration/utils/brands/matchBrandFromUrl'
import { useLabels } from 'labels/hooks/useLabels/useLabels'
import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'
import { usePathname } from 'next/navigation'
import { useEffect, useState } from 'react'
import type { Brand } from 'types/brands'
import { useUserData } from 'user-data/hooks/useUserData'

/**
 * Returns the brand and subBrand from the URL or the master data if the URL does not contain a brand.
 * @returns The brand and subBrand
 */
export const useNavigationBrand = () => {
	const { t } = useLabels()
	const { country } = useMasterData()
	const { userBrandID, userSubBrandID } = useUserData()
	const pathname = usePathname()
	const navBrand = matchBrandFromUrl({
		brands: country.brands,
		urlBrands: pathname.split('/'),
		translate: t,
	})

	const navParentBrand = navBrand
		? getBrandParent(navBrand.id, country.brands)
		: undefined
	const navSubBrand =
		navParentBrand && navParentBrand.id !== navBrand?.id ? navBrand : undefined

	const [brand, setBrand] = useState<Brand | undefined>(navParentBrand)
	const [subBrand, setSubBrand] = useState<Brand | undefined>(navSubBrand)

	useEffect(() => {
		if (!navBrand && userBrandID) {
			const userDataBrand = country.brands.find(
				(countryBrand: Brand) => countryBrand.id === userBrandID
			)
			const userDataSubBrand = userDataBrand?.subBrands?.find(
				(userSubBrand) => userSubBrand.id === userSubBrandID
			)

			setBrand(userDataBrand)
			setSubBrand(userDataSubBrand)
		}
		if (navBrand?.id === userBrandID) {
			const hasSubBrands = country.brands.find(
				(countryBrand: Brand) => countryBrand.id === userBrandID
			)?.subBrands

			if (hasSubBrands) {
				const userDataSubBrand = hasSubBrands.find(
					(userSubBrand) => userSubBrand.id === userSubBrandID
				)

				setSubBrand(userDataSubBrand)
			}
		}
	}, [userBrandID, userSubBrandID])

	return {
		brand,
		subBrand,
	}
}
