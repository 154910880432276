export enum LabelsNamespaces {
	Accessibility = 'Accessibility',
	Apps = 'Apps',
	ATC_Form = 'ATC_form',
	Brands = 'Brands',
	Campaigns_Promotions = 'Campaigns_Promotions',
	Checkout = 'Checkout',
	Client = 'Client',
	Cookies = 'Cookies',
	Discover = 'Discover_by_Mango',
	Filters = 'Filters',
	Footer = 'Footer',
	Forms = 'Forms',
	Gift_Voucher = 'Gift_card',
	Header = 'Header',
	Homes_Outlet = 'Homes_Outlet',
	Homes_Shop = 'Homes_Shop',
	Landing_Mango_Likes_You = 'Landing_Mango_Likes_You',
	Loyalty = 'Loyalty',
	Menu_Common = 'Menu_Common_Keys',
	Menu_Outlet = 'Menu_outlet',
	Menu_Shop = 'Menu_shop',
	Modal_conditions_PDP = 'Modal_conditions_PDP',
	MLY_Club_Mango_Likes_You = 'Club_Mango_Likes_You',
	MLY_PartnersONG = 'MLY_PartnersONG',
	My_Addresses = 'My_addresses',
	My_Details = 'My_details',
	My_profile = 'My_profile',
	My_purchases = 'My_purchases',
	Navigation = 'Navigation',
	Notify_me = 'Notify_me',
	Payment_Checkout = 'Payment_Checkout',
	Performance = 'Performance',
	Prehome_Country_Selector = 'Prehome_Country_Selector',
	Prices = 'Prices',
	Product_Card = 'Product_card',
	Promos_Alerts_Checkout = 'Promos_Alerts_Checkout',
	Promotions_Outlet = 'Outlet_Promotions',
	Promotions_Shop = 'Promotions_Shop',
	Post_Purchase = 'Post_Purchase',
	Pdp = 'PDP',
	Plp = 'PLP',
	Plp_Pdp = 'Plp_Pdp',
	Purchase_confirmation = 'Purchase_confirmation',
	Returns_and_refunds = 'Returns_and_refunds',
	Search_Bar = 'Search_Bar',
	Shopping_Bag = 'Shopping_Bag',
	Shopping_Bag_Checkout = 'Shopping_Bag_Checkout',
	Signin_Create_account = 'Signin_Create_account',
	Size_Guide = 'Size_Guide',
	Store_Locator = 'Store_locator',
	Subscribe_Newsletter = 'Subscribe_Newsletter',
	Transversal_Keys = 'Transversal_Keys',
	USA_privacy_form = 'USA_privacy_form',
	Wishlist = 'Wishlist',
	Backoffice = 'Backoffice',
	Help_Chatbot = 'UX_Help_Chatbot',
}

export type Labels<T extends string = string> = Record<T, string>

export type LabelTranslation = {
	value: string
}

export type LabelsTranslations = Record<string, LabelTranslation>
