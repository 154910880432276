import { env } from 'utils/envManager'

import { SERVER_PATH } from '../../../../constants/CIAM'

export const initialize = (isOldSDK = false) => {
	if (isOldSDK) {
		window.myTSAccountProtection = new window.TSAccountProtection(
			env.NEXT_PUBLIC_CIAM_CLIENT_ID,
			{
				serverPath: `${env.NEXT_PUBLIC_API_DOMAIN}${SERVER_PATH}`,
			}
		)
		window.myTSAccountProtection.init()
	} else {
		window.tsPlatform.initialize({
			clientId: env.NEXT_PUBLIC_CIAM_CLIENT_ID,
			drs: {
				serverPath: `${env.NEXT_PUBLIC_API_DOMAIN}${SERVER_PATH}`,
			},
		})
	}
}
