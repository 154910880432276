import { useContext } from 'react'

import { HeaderStateContext } from './HeaderContext'

export const useHeaderState = () => {
	const context = useContext(HeaderStateContext)

	if (context === undefined) {
		throw new Error('useMenuState must be used within a HeaderProvider')
	}

	return context
}
