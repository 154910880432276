import { env } from 'utils/envManager'

export const getRecommendation = async (actionToken: string): Promise<void> => {
	const url = new URL(
		'/ws-mngidp/drs/recommendation',
		env.NEXT_PUBLIC_SITE_BASE_URL
	)

	await fetch(url.href, {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify({ actionToken }),
		next: { revalidate: 0 },
	})
}
